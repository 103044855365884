import React, { Fragment, useState } from "react";
import { SharedMatrix } from "../../mks-matrix/shared-matrix";

export function Credentials({ makeRequest, credentialsPromise }) {
  const client = new SharedMatrix().getClient();
  const [state, setState] = useState({
    password: "",
    otp: "",
    error: null,
  });

  const tryToUpload = async () => {
    const authDict = {
      type: "de.monks.password",
      password: state.password,
      otp: state.otp,
      identifier: {
        type: "m.id.user",
        user: client.getUserId(),
      },
    };

    const response = await makeRequest(authDict).catch(() => {
      setState({ ...state, error: "ungültig" });
    });

    if (response) credentialsPromise();
  };

  return (
    <Fragment>
      {state.error ? <p>{state.error}</p> : null}
      <label>
        Password
        <input
          value={state.password}
          onChange={(event) =>
            setState({ ...state, password: event.target.value })
          }
        />
      </label>
      <label>
        OTP
        <input
          value={state.otp}
          onChange={(event) => setState({ ...state, otp: event.target.value })}
        />
      </label>
      <button onClick={tryToUpload}>Send</button>
    </Fragment>
  );
}
