// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "./controllers";
import { SharedMatrix } from "./mks-matrix/shared-matrix";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((rs) => {
    rs.forEach((r) => r.update());
  });

  window.addEventListener("load", function () {
    navigator.serviceWorker.register("/service-worker.js").then(
      function (registration) {
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope,
        );
      },
      function (error) {
        console.error("ServiceWorker registration failed: ", error);
      },
    );
  });
}

addEventListener("turbo:submit-start", ({ target }) => {
  if (target.action.includes("users/sign_out")) {
    new SharedMatrix().logout();
  }
});
