import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { Loader } from "../Loader";
import { SharedMatrix } from "../../mks-matrix/shared-matrix";
import { Credentials } from "./Credentials";

const initialState = {
  newKeySaved: false,
  newKey: null,
  credentialsPromise: null,
  makeRequest: null,
  loading: false,
};

export function SetupBackup({ close }) {
  const [state, setState] = useState(initialState);
  const client = new SharedMatrix().getClient();

  const createNewKey = async () => {
    const key = await client.getCrypto().createRecoveryKeyFromPassphrase();
    setState({ ...state, newKey: key });
  };

  useEffect(() => {
    createNewKey();
  }, []);

  const downloadNewKey = () => {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(state.newKey.encodedPrivateKey),
    );
    element.setAttribute("download", "mein-jugendamt-schluessel");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setState({ ...state, newKeySaved: true });
  };

  const copyNewKey = () => {
    navigator.clipboard.writeText(state.newKey.encodedPrivateKey);
    setState({ ...state, newKeySaved: true });
  };

  const uploadKeys = async (makeRequest) => {
    console.log("[uploadKeys] starting");
    var promiseResolve, promiseReject;

    var promise = new Promise(function (resolve, reject) {
      promiseResolve = resolve;
      promiseReject = reject;
    });

    setState({
      ...state,
      credentialsPromise: promiseResolve,
      makeRequest: makeRequest,
    });

    return promise;
  };

  const setupBackup = () => {
    setState({ ...state, loading: true });
    const matrix = new SharedMatrix().getMatrix();
    matrix.setupBackup(state.newKey, uploadKeys);
  };

  return React.createElement(
    Modal,
    {
      title: "Verschlüsselung einrichten",
      close: close,
    },
    state.credentialsPromise && state.makeRequest
      ? React.createElement(Credentials, {
          makeRequest: state.makeRequest,
          credentialsPromise: state.credentialsPromise,
        })
      : React.createElement(
          React.Fragment,
          null,
          state.pwdError,
          state.pwdError ? React.createElement("p", {}, state.pwdError) : null,
          React.createElement(
            "p",
            {},
            "Speichere deinen geheimen Schlüssel ab und bewahre ihn sorgsam auf:",
          ),
          React.createElement(
            "div",
            { className: "border border-radius-6 padding-6" },
            state?.newKey?.encodedPrivateKey,
          ),
          React.createElement(
            "button",
            { className: "margin-10-0", onClick: copyNewKey },
            "Kopieren",
          ),
          React.createElement(
            "button",
            { className: "margin-10-0 margin-left-8", onClick: downloadNewKey },
            "Herunterladen",
          ),
          state.loading
            ? React.createElement(Loader)
            : React.createElement(
                "button",
                {
                  className: "full-width",
                  disabled: !state.newKeySaved,
                  onClick: setupBackup,
                },
                "Weiter",
              ),
        ),
  );
}
