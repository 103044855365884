import React, { useState, useEffect } from "react";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import { Shield } from "./icons/Shield";
import { Loader } from "./Loader";
import { BackupByKey } from "./Modals/BackupByKey";
import { SetupBackup } from "./Modals/SetupBackup";

const initialState = {
  running: false,
  showSetup: false,
  showSetupModal: false,
  newKey: null,
  newKeySaved: false,
  showBackupModal: false,
  locked: false,
  lockedLoadingMessage: null,
};

export function Matrix() {
  const [state, setState] = useState(initialState);
  const matrix = new SharedMatrix().getMatrix();

  const onSetup = async () => {
    setState({ ...initialState, showSetup: true });
  };

  const onBackup = async () => {
    setState({ ...initialState, showBackup: true });
  };

  const onRunningEncrypted = async () => {
    setState({ ...initialState, running: true });
  };

  const onLocked = () => {
    setState({ ...initialState, locked: true });
  };

  const setLockedLoadingMessage = (message) => {
    setState({ ...state, lockedLoadingMessage: message });
  };

  useEffect(() => {
    matrix.onLocked(onLocked);
    matrix.onSetup(onSetup);
    matrix.onRunningEncrypted(onRunningEncrypted);
    matrix.onBackup(onBackup);
    matrix.start();
  }, []);

  const backgroundColor = state.running ? "" : "background-orange";
  let shieldedContent = React.createElement(Loader);

  if (state.showSetup)
    shieldedContent = React.createElement(
      "div",
      {},
      "Ende-zu-Ende-Verschlüsselung noch nicht eingerichtet",
      React.createElement(
        "button",
        {
          onClick: () => {
            setState({ ...state, showSetupModal: true });
          },
        },
        "jetzt einrichten",
      ),
      state.showSetupModal &&
        React.createElement(SetupBackup, {
          close: () => setState({ ...state, showSetupModal: false }),
        }),
    );
  if (state.showBackup)
    shieldedContent = React.createElement(
      "div",
      {},
      "Schlüssel benötigt",
      React.createElement(
        "button",
        {
          onClick: () => {
            setState({ ...state, showBackupModal: true });
          },
        },
        "eingeben",
      ),
      state.showBackupModal &&
        React.createElement(BackupByKey, {
          close: () => setState({ ...state, showBackupModal: false }),
          reset: () =>
            setState({
              ...initialState,
              showSetup: true,
              showSetupModal: true,
            }),
        }),
    );
  if (state.running) {
    return null;
    // TODO: fadeout content
    // shieldedContent = "Ende-zu-Ende-Verschlüsselung einsatzbereit";
  }

  if (state.locked) {
    if (state.lockedLoadingMessage) {
      return React.createElement(
        "div",
        {
          className:
            "encryption-child flex align-items-center gap-12 " +
            backgroundColor,
        },
        state.lockedLoadingMessage,
        React.createElement(Loader),
      );
    }
    return React.createElement(
      "div",
      {
        className:
          "encryption-child flex align-items-center gap-12 " + backgroundColor,
      },
      "Die Anwendung läuft bereits in einem anderen Tab/Fenster.",
      React.createElement(
        "button",
        {
          onClick: () => {
            matrix.start(true, setLockedLoadingMessage);
          },
        },
        "übernehmen",
      ),
    );
  }

  return React.createElement(
    "div",
    {
      className:
        "encryption-child flex align-items-center gap-12 " + backgroundColor,
    },
    React.createElement("div", {}, React.createElement(Shield)),
    shieldedContent,
  );
}
