import { initClient } from "./session";
import * as buffer from "buffer";
import {
  checkSessionLockFree,
  getSessionLock,
} from "matrix-react-sdk/lib/utils/SessionLock";
import { request } from "../utils/request";

window.Buffer = buffer.Buffer;
window.global = window;

export class MksMatrix {
  homeserverUrl = null;
  token = null;
  matrixId = null;
  client = null;
  key = null;

  setToken = (token) => {
    this.token = token;
  };
  setHomeserverUrl = (homeserverUrl) => {
    this.homeserverUrl = homeserverUrl;
  };

  setMatrixUserId = (matrixId) => {
    this.matrixId = matrixId;
  };

  getSecretStorageKey = async ({ keys: keyInfos }) => {
    if (!this.client || !this.key) return null;

    let keyId = await this.client.getDefaultSecretStorageKeyId();
    let keyInfo;

    if (keyId) {
      keyInfo = keyInfos[keyId];
      if (!keyInfo) {
        keyId = null;
      }
    }

    if (!keyId) {
      const keyInfoEntries = Object.entries(keyInfos);
      if (keyInfoEntries.length > 1) {
        throw new Error("Multiple storage key requests not implemented");
      }
      [keyId, keyInfo] = keyInfoEntries[0];
    }

    if (!keyInfo) return null;

    return [keyId, await this.client.keyBackupKeyFromRecoveryKey(this.key)];
  };

  start = async (forceTakeover = false, setLockedLoadingMessage = () => {}) => {
    if (!checkSessionLockFree() && !forceTakeover) {
      this.emitLocked();
      return;
    }
    const self = this;
    setLockedLoadingMessage(
      "Sitzung übernehmen. (Kann bis zu 30 Sekunden dauern...)",
    );
    if (
      !(await getSessionLock(() => {
        new Promise((resolve, reject) => {
          this.client?.stopClient();
          this.client?.removeAllListeners();
          this.emitLocked();
          this.client = undefined;
          resolve();
        });
      }))
    ) {
      this.emitLocked();
      setLockedLoadingMessage(
        "Das übernehmen der Sitzung ist fehlgeschlagen.",
      );
      return;
    }
    setLockedLoadingMessage("Sitzung übernommen. Anwenung wird gestartet...");
    if (!this.token || !this.matrixId || !this.homeserverUrl)
      throw "Data missing to start client.";
    if (!this.client) {
      this.client = await initClient(
        this.homeserverUrl,
        this.matrixId,
        this.token,
        {
          getSecretStorageKey: self.getSecretStorageKey,
        },
      );
    }
    try {
      await this.client.initRustCrypto();
    } catch (error) {
      console.warn(error);
      try {
        await this.client.clearStores();
        await this.client.initRustCrypto();
      } catch (error) {
        console.error(error);
      }
    }
    const client = this.client;
    this.client.on("RoomMember.membership", function (event, member) {
      if (
        member.membership === "invite" &&
        member.userId === client.getUserId()
      ) {
        client.joinRoom(member.roomId);
      }
    });
    await this.client.store.startup();
    await this.client.startClient({
      initialSyncLimit: 50,
    });
    this.emitRunning();
    const backupInfo = await this.client.getKeyBackupVersion();
    if (!backupInfo) {
      this.emitSetup();
      return;
    }

    const isCrossSigningReady = await this.client
      .getCrypto()
      .isCrossSigningReady();
    if (!isCrossSigningReady) {
      this.emitBackup();
      return;
    }

    this.emitRunningEncrypted();
  };

  running = () => {
    if (!this.client) return false;

    return this.client.clientRunning;
  };

  runningEncrypted = async () => {
    if (!this.running()) return false;

    const backupInfo = await this.client.getKeyBackupVersion();
    if (!backupInfo) return false;

    const isCrossSigningReady = await this.client
      .getCrypto()
      .isCrossSigningReady();
    if (!isCrossSigningReady) {
      return false;
    }
    return true;
  };

  getClient = () => {
    return this.client;
  };

  async tryToBackup(providedEncrypedkey) {
    setupPush(this.client);
    this.key = providedEncrypedkey;
    try {
      await this.client.getCrypto().bootstrapCrossSigning({
        setupNewCrossSigning: false,
      });
    } catch {
      return "Der Schlüssel scheint falsch zu sein.";
    }
    const backupInfo = await this.client.getKeyBackupVersion();
    await this.client.restoreKeyBackupWithSecretStorage(backupInfo);
    await this.client.getCrypto().startDehydration();
    this.key = null;
    this.emitRunningEncrypted();
  }

  async setupBackup(key, uploadKeys) {
    setupPush(this.client);
    try {
      this.key = key.encodedPrivateKey;

      console.log("Bootstrapping Cross Signing...");
      await this.client.getCrypto().bootstrapCrossSigning({
        authUploadDeviceSigningKeys: async (makeRequest) => {
          await uploadKeys(makeRequest);
        },
        setupNewCrossSigning: true,
      });
      console.log(
        "Cross Signing ready. continuing with secret storage........",
      );
      await this.client.getCrypto().bootstrapSecretStorage({
        createSecretStorageKey: async () => {
          return { privateKey: key.privateKey, keyInfo: {} };
        },
        setupNewKeyBackup: true,
        setupNewSecretStorage: true,
      });
      await this.client.getCrypto().checkKeyBackupAndEnable();
      console.log("Both are set up!");
      await this.client.getCrypto().startDehydration(true);
      this.emitRunningEncrypted();
    } catch (error) {
      console.error(error);
      return "Etwas ist schiefgelaufen.";
    }
  }

  async logout() {
    localStorage.clear();
    await this.client.logout(true);
    await this.client.clearStores();
  }

  // EVENTS =====================================

  setupHandler = [];
  onSetup = (handler) => {
    this.setupHandler.push(handler);
  };
  emitSetup = () => {
    this.setupHandler.forEach((handler) => {
      handler();
    });
  };

  backupHandler = [];
  onBackup = (handler) => {
    this.backupHandler.push(handler);
  };
  emitBackup = () => {
    this.backupHandler.forEach((handler) => {
      handler();
    });
  };

  runnningEncryptedHandler = [];
  onRunningEncrypted = (handler) => {
    this.runnningEncryptedHandler.push(handler);
  };
  emitRunningEncrypted = () => {
    this.runnningEncryptedHandler.forEach((handler) => {
      handler();
    });
  };

  runnningHandler = [];
  onRunning = (handler) => {
    this.runnningHandler.push(handler);
  };
  emitRunning = () => {
    this.runnningHandler.forEach((handler) => {
      handler();
    });
  };

  lockedHandler = [];
  onLocked = (handler) => {
    this.lockedHandler.push(handler);
  };
  emitLocked = () => {
    this.lockedHandler.forEach((handler) => {
      handler();
    });
  };

  setupPush = async () => {
    return await setupPush(this.client);
  };
}

const setupPush = async (client) => {
  if (!("Notification" in window)) return false;
  const dataDiv = document.querySelector("#pushgatewayurl");
  const pushgatewayurl = dataDiv.dataset.pushgatewayurl;
  const permission = await Notification.requestPermission().catch((err) => {
    console.error(err);
  });
  if (!permission) return false;
  // Use serviceWorker.ready to ensure that you can subscribe for push
  const serviceWorkerRegistration = await navigator.serviceWorker.ready;
  const options = {
    userVisibleOnly: true,
    applicationServerKey:
      "BGsAW41gGyVheYnlJiXeOqQYCJmKLgjSggYGJyYP__4w4hcQs65YhilD186kSNfYflO-5QkTAlQQAcWmku2Ad3s",
  };
  const pushSubscription =
    await serviceWorkerRegistration.pushManager.subscribe(options);
  if (!pushSubscription) return false;
  const response = await request(
    "/push_subscriptions",
    "PUT",
    pushSubscription.toJSON(),
  );
  if (!response) return false;
  const a = await client.setPusher({
    app_display_name: "ByJuConnect",
    app_id: "1",
    data: {
      url: pushgatewayurl,
    },
    device_display_name: "Web App",
    kind: "http",
    lang: "de",
    pushkey: response.token.toString(),
  });
  if (a) {
    return true;
  }
  return false;
};
