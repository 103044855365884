import { MksMatrix } from "./matrix";

export class SharedMatrix {
  matrix = null;

  getClient() {
    return this.getMatrix().getClient();
  }

  getMatrix() {
    if (window.mks_mx_matrix) return window.mks_mx_matrix;

    const matrix = new MksMatrix();
    window.mks_mx_matrix = matrix;
    return matrix;
  }

  logout() {
    this.getMatrix().logout();
    window.mks_mx_matrix = undefined;
  }
}
